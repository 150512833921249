import { render, staticRenderFns } from "./MyDishes.vue?vue&type=template&id=c42c0c08"
import script from "./MyDishes.vue?vue&type=script&lang=js"
export * from "./MyDishes.vue?vue&type=script&lang=js"
import style0 from "./MyDishes.vue?vue&type=style&index=0&id=c42c0c08&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports